<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="20"
    width="20"
  >
    <path
      fill="currentColor"
      d="M4.167 10.833V9.083H15.833V10.833Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'icon-remove',
};
</script>

<style
  lang="scss"
  scoped
></style>
