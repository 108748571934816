<template>
  <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M22 7.81V12.5H17.92C17.8 12.49 17.54 12.34 17.48 12.23L16.44 10.26C16.03 9.48 15.32 9.04 14.56 9.08C13.8 9.12 13.15 9.63 12.82 10.46L11.44 13.92L11.24 13.4C10.75 12.13 9.35 11.17 7.97 11.17L2 11.2V7.81C2 4.17 4.17 2 7.81 2H16.19C19.83 2 22 4.17 22 7.81Z"
      fill="currentColor"
    />
    <path
      d="M22 16.19V14H17.92C17.25 14 16.46 13.52 16.15 12.93L15.11 10.96C14.83 10.43 14.43 10.46 14.21 11.01L11.91 16.82C11.66 17.47 11.24 17.47 10.98 16.82L9.84 13.94C9.57 13.24 8.73 12.67 7.98 12.67L2 12.7V16.19C2 19.77 4.1 21.93 7.63 21.99C7.74 22 7.86 22 7.97 22H15.97C16.12 22 16.27 22 16.41 21.99C19.92 21.91 22 19.76 22 16.19Z"
      fill="currentColor"
    />
    <path d="M1.99997 12.7V16.01C1.97997 15.69 1.96997 15.35 1.96997 15V12.7H1.99997Z" fill="currentColor" />
  </svg>
</template>

<script>
export default {
  name: "icon-health",
};
</script>

<style lang="scss" scoped></style>
