<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.9493 7.04032L6.79017 13.3223C6.5576 13.5609 6.33254 14.0308 6.28753 14.3561L6.00995 16.6983C5.91243 17.5441 6.5426 18.1224 7.41284 17.9778L9.8285 17.5802C10.1661 17.5224 10.6387 17.2838 10.8713 17.0381L17.0305 10.756C18.0957 9.67168 18.5759 8.43552 16.9179 6.92465C15.2675 5.42824 14.0146 5.95596 12.9493 7.04032Z"
      fill="currentColor"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M11.9216 8.08853C12.2442 10.0837 13.9247 11.6091 16.0102 11.8115"
      stroke="white"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: 'icon-edit',
};
</script>

<style
  lang="scss"
  scoped
></style>
