<template>
  <svg
    width="15"
    height="12"
    viewBox="0 0 15 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.5882 0H3.52941C1.41176 0 0 1.05882 0 3.52941V8.47059C0 10.9412 1.41176 12 3.52941 12H10.5882C12.7059 12 14.1176 10.9412 14.1176 8.47059V3.52941C14.1176 1.05882 12.7059 0 10.5882 0ZM10.92 4.29882L8.71059 6.06353C8.24471 6.43765 7.65176 6.62118 7.05882 6.62118C6.46588 6.62118 5.86588 6.43765 5.40706 6.06353L3.19765 4.29882C2.97176 4.11529 2.93647 3.77647 3.11294 3.55059C3.29647 3.32471 3.62824 3.28235 3.85412 3.46588L6.06353 5.23059C6.6 5.66118 7.51059 5.66118 8.04706 5.23059L10.2565 3.46588C10.4824 3.28235 10.8212 3.31765 10.9976 3.55059C11.1812 3.77647 11.1459 4.11529 10.92 4.29882Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: 'icons-sms',
};
</script>

<style
  lang="scss"
  scoped
></style>
