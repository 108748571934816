<template>
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0 2C0 1.46957 0.210714 0.960859 0.585787 0.585787C0.960859 0.210714 1.46957 0 2 0H10C10.5304 0 11.0391 0.210714 11.4142 0.585787C11.7893 0.960859 12 1.46957 12 2V10C12 10.5304 11.7893 11.0391 11.4142 11.4142C11.0391 11.7893 10.5304 12 10 12H2C1.46957 12 0.960859 11.7893 0.585787 11.4142C0.210714 11.0391 0 10.5304 0 10V2ZM6.66667 1.33333C6.48986 1.33333 6.32029 1.40357 6.19526 1.5286C6.07024 1.65362 6 1.82319 6 2C6 2.17681 6.07024 2.34638 6.19526 2.4714C6.32029 2.59643 6.48986 2.66667 6.66667 2.66667H8.39067L5.52867 5.52867C5.40723 5.6544 5.34003 5.8228 5.34155 5.9976C5.34307 6.1724 5.41318 6.33961 5.53679 6.46321C5.66039 6.58682 5.8276 6.65693 6.0024 6.65845C6.1772 6.65997 6.3456 6.59277 6.47133 6.47133L9.33333 3.60933V5.33333C9.33333 5.51014 9.40357 5.67971 9.52859 5.80474C9.65362 5.92976 9.82319 6 10 6C10.1768 6 10.3464 5.92976 10.4714 5.80474C10.5964 5.67971 10.6667 5.51014 10.6667 5.33333V2C10.6667 1.82319 10.5964 1.65362 10.4714 1.5286C10.3464 1.40357 10.1768 1.33333 10 1.33333H6.66667Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: "icon-link",
};
</script>

<style lang="scss" scoped></style>
