<template>
  <svg
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.81824 8.4524L2.81824 16.5785C2.81824 20.1082 4.92248 22.2124 8.45218 22.2124H16.5685C20.1079 22.2124 22.2122 20.1082 22.2122 16.5785V8.4621C22.2122 4.9324 20.1079 2.82816 16.5782 2.82816H8.45218C4.92248 2.81846 2.81824 4.9227 2.81824 8.4524ZM14.9394 14.3288C15.2207 14.0476 15.6861 14.0476 15.9673 14.3288C16.1128 14.4742 16.1807 14.6585 16.1807 14.8427C16.1807 15.0269 16.1128 15.2112 15.9673 15.3566L13.5528 17.7712C12.9807 18.3433 12.0594 18.3433 11.4873 17.7712L9.07278 15.3566C8.79157 15.0754 8.79157 14.61 9.07278 14.3288C9.35399 14.0476 9.81945 14.0476 10.1007 14.3288L12.5152 16.7433L14.9394 14.3288ZM13.5431 7.25967L15.9576 9.67422C16.1031 9.81967 16.171 10.0039 16.171 10.1882C16.171 10.3724 16.1031 10.5566 15.9576 10.7021C15.6764 10.9833 15.211 10.9833 14.9298 10.7021L12.5152 8.28755L10.091 10.7021C9.80975 10.9833 9.3443 10.9833 9.06309 10.7021C8.78187 10.4209 8.78187 9.95543 9.06309 9.67422L11.4776 7.25967C12.0498 6.68755 12.9807 6.68755 13.5431 7.25967Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: 'icon-scroll',
};
</script>

<style
  lang="scss"
  scoped
></style>
