<template>
  <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M15.11 2.48999C11.06 2.48999 9.21002 3.95999 8.90002 7.55999L8.90413 11.25H3.62C3.20578 11.25 2.87 11.5858 2.87 12C2.87 12.4142 3.20578 12.75 3.62 12.75H8.9058L8.91002 16.54C9.24002 20.08 11.09 21.53 15.11 21.53H15.24C19.71 21.53 21.5 19.74 21.5 15.27V8.74999C21.5 4.27999 19.71 2.48999 15.24 2.48999H15.11ZM8.9058 12.75H15C15.4142 12.75 15.75 12.4142 15.75 12C15.75 11.5858 15.4142 11.25 15 11.25H8.90413L8.9058 12.75Z"
      fill="currentColor"
    />
    <path
      d="M5.85 8.64999L2.5 12L5.85 15.35"
      fill="currentColor"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: "icon-logout",
};
</script>

<style lang="scss" scoped></style>
