<template>
  <div class="popper-notify"></div>
</template>

<script>
export default {
  name: 'popper-notify',
};
</script>

<style
  lang="scss"
  scoped
></style>
