<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.4 9.67484C17.552 6.70811 14.848 5 12 5C10.576 5 9.192 5.42499 7.928 6.21775C6.664 7.01868 5.528 8.18739 4.6 9.67484C3.8 10.958 3.8 13.042 4.6 14.3252C6.448 17.3001 9.152 19 12 19C13.424 19 14.808 18.575 16.072 17.7823C17.336 16.9813 18.472 15.8126 19.4 14.3252C20.2 13.0502 20.2 10.958 19.4 9.67484ZM12 15.3059C10.208 15.3059 8.768 13.8266 8.768 12.0041C8.768 10.1816 10.208 8.70228 12 8.70228C13.792 8.70228 15.232 10.1816 15.232 12.0041C15.232 13.8266 13.792 15.3059 12 15.3059Z"
      fill="currentColor"
    />
    <path
      d="M12.0001 9.66675C10.7441 9.66675 9.72009 10.7129 9.72009 12.0042C9.72009 13.2873 10.7441 14.3334 12.0001 14.3334C13.2561 14.3334 14.2881 13.2873 14.2881 12.0042C14.2881 10.721 13.2561 9.66675 12.0001 9.66675Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: 'icon-eye',
};
</script>

<style
  lang="scss"
  scoped
></style>
