<template>
  <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15 0.43H5C2 0.43 0 2.43 0 5.43V11.43C0 14.43 2 16.43 5 16.43V18.56C5 19.36 5.89 19.84 6.55 19.39L11 16.43H15C18 16.43 20 14.43 20 11.43V5.43C20 2.43 18 0.43 15 0.43ZM10 12.6C9.58 12.6 9.25 12.26 9.25 11.85C9.25 11.44 9.58 11.1 10 11.1C10.42 11.1 10.75 11.44 10.75 11.85C10.75 12.26 10.42 12.6 10 12.6ZM11.26 8.45C10.87 8.71 10.75 8.88 10.75 9.16V9.37C10.75 9.78 10.41 10.12 10 10.12C9.59 10.12 9.25 9.78 9.25 9.37V9.16C9.25 8 10.1 7.43 10.42 7.21C10.79 6.96 10.91 6.79 10.91 6.53C10.91 6.03 10.5 5.62 10 5.62C9.5 5.62 9.09 6.03 9.09 6.53C9.09 6.94 8.75 7.28 8.34 7.28C7.93 7.28 7.59 6.94 7.59 6.53C7.59 5.2 8.67 4.12 10 4.12C11.33 4.12 12.41 5.2 12.41 6.53C12.41 7.67 11.57 8.24 11.26 8.45Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: "icon-support",
};
</script>

<style lang="scss" scoped></style>
