<template>
  <svg
    stroke="currentColor"
    fill="currentColor"
    stroke-width="0"
    viewBox="0 0 24 24"
    height="200px"
    width="200px"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill="none"
      d="M0 0h24v24H0z"
    ></path>
    <path
      d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM6.5 9 10 5.5 13.5 9H11v4H9V9H6.5zm11 6L14 18.5 10.5 15H13v-4h2v4h2.5z"
    ></path>
  </svg>
</template>
  
  <script>
export default {
  name: 'icon-swap',
};
</script>
  
  <style lang="scss" scoped></style>
  