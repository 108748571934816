<template>
  <svg width="18" height="9" viewBox="0 0 18 9" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17 1.00006L10.48 7.52006C9.71003 8.29006 8.45003 8.29006 7.68003 7.52006L1.16003 1.00006"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: "icon-arrow-bottom",
};
</script>

<style lang="scss" scoped></style>
