<template>
  <svg width="12" height="12" fill="none" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.765 7.965C10.685 7.83 10.46 7.62 9.89998 7.72C9.58998 7.775 9.27498 7.8 8.95998 7.785C7.79498 7.735 6.73998 7.2 6.00498 6.375C5.35498 5.65 4.95498 4.705 4.94998 3.685C4.94998 3.115 5.05998 2.565 5.28498 2.045C5.50498 1.54 5.34998 1.275 5.23998 1.165C5.12498 1.05 4.85498 0.889997 4.32498 1.11C2.27998 1.97 1.01498 4.02 1.16498 6.215C1.31498 8.28 2.76498 10.045 4.68498 10.71C5.14498 10.87 5.62998 10.965 6.12998 10.985C6.20998 10.99 6.28998 10.995 6.36998 10.995C8.04498 10.995 9.61498 10.205 10.605 8.86C10.94 8.395 10.85 8.1 10.765 7.965Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: "icon-moon",
};
</script>

<style lang="scss" scoped></style>
