export default {
  getLatestNews(state) {
    return state.news;
  },
  getNewsTotal(state) {
    return state.total;
  },
  getNewsCurrent(state) {
    return state.current;
  },
};
