<template>
  <svg width="10" height="6" viewBox="0 0 10 6" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.11777 3.86833L6.97777 1.72833L5.6711 0.415C5.11777 -0.138333 4.21777 -0.138333 3.66444 0.415L0.211102 3.86833C-0.242231 4.32167 0.0844355 5.095 0.717769 5.095H4.45777H8.6111C9.2511 5.095 9.5711 4.32167 9.11777 3.86833Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: "icon-toggle-top",
};
</script>

<style lang="scss" scoped></style>
