<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    color="black"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    stroke-width="2"
    stroke-linecap="round"
    stroke-linejoin="round"
    class="icon icon-tabler icons-tabler-outline icon-tabler-chevron-down"
    
  >
    <path
      stroke="none"
      d="M0 0h24v24H0z"
      fill="none"
    />
    <path d="M6 9l6 6l6 -6" />
  </svg>
</template>
  
  <script>
export default {
  name: 'icon-dropdown',
};
</script>
  
  <style lang="scss" scoped>
svg{
  width:18px;
}
</style>
  