<template>
  <svg
    stroke="currentColor"
    fill="currentColor"
    stroke-width="0"
    viewBox="0 0 24 24"
    height="200px"
    width="200px"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Warning">
      <g>
        <g>
          <path d="M12.5,8.752a.5.5,0,0,0-1,0h0v6a.5.5,0,0,0,1,0Z"></path>
          <circle
            cx="11.999"
            cy="16.736"
            r="0.5"
          ></circle>
        </g>
        <path
          d="M18.642,20.934H5.385A2.5,2.5,0,0,1,3.163,17.29L9.792,4.421a2.5,2.5,0,0,1,4.444,0L20.865,17.29a2.5,2.5,0,0,1-2.223,3.644ZM12.014,4.065a1.478,1.478,0,0,0-1.334.814L4.052,17.748a1.5,1.5,0,0,0,1.333,2.186H18.642a1.5,1.5,0,0,0,1.334-2.186L13.348,4.879A1.478,1.478,0,0,0,12.014,4.065Z"
        ></path>
      </g>
    </g>
  </svg>
</template>
    
    <script>
export default {
  name: 'slipage-warning',
};
</script>
    
    <style lang="scss" scoped></style>
    