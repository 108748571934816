<template>
  <svg
    width="14"
    height="15"
    viewBox="0 0 14 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.79338 0.833328H4.20671C1.78004 0.833328 0.333374 2.27999 0.333374 4.70666V10.2867C0.333374 12.72 1.78004 14.1667 4.20671 14.1667H9.78671C12.2134 14.1667 13.66 12.72 13.66 10.2933V4.70666C13.6667 2.27999 12.22 0.833328 9.79338 0.833328ZM10.2534 7.18666C10.2534 7.44666 10.0467 7.65333 9.78671 7.65333C9.52671 7.65333 9.32004 7.44666 9.32004 7.18666V7.06666L7.50671 8.88C7.40671 8.97999 7.27337 9.02666 7.13337 9.01333C6.99337 9 6.86671 8.92666 6.79337 8.80666L6.11337 7.79333L4.52671 9.38C4.43337 9.47333 4.32004 9.51333 4.20004 9.51333C4.08004 9.51333 3.96004 9.46666 3.87337 9.38C3.69337 9.2 3.69337 8.90666 3.87337 8.72L5.86004 6.73333C5.96004 6.63333 6.09337 6.58666 6.23337 6.59999C6.37337 6.61333 6.50004 6.68666 6.57337 6.80666L7.25337 7.81999L8.66004 6.41333H8.54004C8.28004 6.41333 8.07337 6.20666 8.07337 5.94666C8.07337 5.68666 8.28004 5.48 8.54004 5.48H9.78004C9.84004 5.48 9.90004 5.49333 9.96004 5.51333C10.0734 5.56 10.1667 5.65333 10.2134 5.76666C10.24 5.82666 10.2467 5.88666 10.2467 5.94666V7.18666H10.2534Z"
      fill="#00AC7C"
    />
  </svg>
</template>

<script>
export default {
  name: 'icon-percent-up',
};
</script>

<style
  lang="scss"
  scoped
></style>
