<template>
  <svg
    width="9"
    height="18"
    viewBox="0 0 9 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.00012 0.999966L7.52012 7.51997C8.29012 8.28997 8.29012 9.54997 7.52012 10.32L1.00012 16.84"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: 'icon-arrow-horizontal',
};
</script>

<style
  lang="scss"
  scoped
></style>
