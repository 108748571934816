<template>
  <svg viewBox="0 0 79 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M23.2968 24.6447C22.4512 24.6447 15.914 24.6319 15.0432 24.6064C10.6766 24.2105 7.25657 20.4436 7.25657 15.9871C7.25657 11.4923 10.7019 7.72541 15.1063 7.36787C15.9645 7.3551 22.691 7.34233 23.2968 7.34233C25.3034 7.34233 26.9314 5.69509 26.9314 3.66478C26.9314 1.63447 25.3034 0 23.2968 0C23.221 0 15.1694 0 14.652 0.0383078C6.43627 0.651232 0 7.64879 0 15.9999C0 20.0477 1.5018 23.9041 4.21512 26.8793C6.91583 29.829 10.5757 31.6295 14.5258 31.9487C15.0937 31.9998 23.2084 31.9998 23.2841 31.9998C25.2907 31.9998 26.9187 30.3525 26.9187 28.3222C26.9187 26.2919 25.3034 24.6447 23.2968 24.6447Z"
      fill="#01B7FF"
    />
    <path
      d="M41.1968 15.2085C39.3921 14.3146 37.2215 15.068 36.3381 16.894L31.6434 26.7136C30.76 28.5396 31.5045 30.7359 33.3092 31.6297C33.8267 31.8851 34.3693 32 34.8994 32C36.2371 32 37.5244 31.2467 38.1554 29.9442L42.8501 20.1246C43.7335 18.2986 42.9889 16.1023 41.1968 15.2085Z"
      fill="#01B7FF"
    />
    <path
      d="M50.2574 2.05611C49.374 0.230109 47.2034 -0.510508 45.3987 0.370571C43.594 1.26442 42.862 3.46073 43.7328 5.28674L55.6841 29.9314C56.3151 31.2339 57.6023 31.9873 58.9401 31.9873C59.4827 31.9873 60.0254 31.8723 60.5302 31.617C62.3349 30.7231 63.0669 28.5268 62.1961 26.7008L50.2574 2.05611Z"
      fill="#01B7FF"
    />
    <path
      d="M78.6285 26.7008L66.6772 2.05611C65.7938 0.230109 63.6232 -0.510508 61.8185 0.370571C60.0138 1.26442 59.2818 3.46073 60.1526 5.28674L72.1039 29.9314C72.7349 31.2339 74.0221 31.9873 75.3599 31.9873C75.8899 31.9873 76.4452 31.8723 76.95 31.617C78.7673 30.7231 79.5119 28.5268 78.6285 26.7008Z"
      fill="#01B7FF"
    />
  </svg>
</template>

<script>
export default {
  name: "icon-logo",
};
</script>

<style lang="scss" scoped></style>
