<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="24"
    width="24"
  >
    <path
      d="M3 18V16H21V18ZM3 13V11H21V13ZM3 8V6H21V8Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: 'icon-burger',
};
</script>

<style
  lang="scss"
  scoped
></style>
