<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="mask0_720_5315" style="mask-type: alpha" maskUnits="userSpaceOnUse" x="3" y="5" width="18" height="14">
      <path
        d="M8.79506 15.875L5.32506 12.405C5.13823 12.2178 4.88458 12.1125 4.62006 12.1125C4.35554 12.1125 4.10189 12.2178 3.91506 12.405C3.52506 12.795 3.52506 13.425 3.91506 13.815L8.09506 17.995C8.48506 18.385 9.11506 18.385 9.50506 17.995L20.0851 7.41503C20.4751 7.02503 20.4751 6.39503 20.0851 6.00503C19.8982 5.81777 19.6446 5.71254 19.3801 5.71254C19.1155 5.71254 18.8619 5.81777 18.6751 6.00503L8.79506 15.875Z"
        fill="currentColor"
      />
    </mask>
    <g mask="url(#mask0_720_5315)">
      <rect width="24" height="24" fill="currentColor" />
    </g>
  </svg>
</template>

<script>
export default {
  name: "icon-check",
};
</script>

<style lang="scss" scoped></style>
