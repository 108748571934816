<template>
  <svg width="14" height="14" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.3478 0.458889C11.0522 0.163333 10.6556 0 10.2433 0H1.55556C1.143 0 0.747335 0.163888 0.455612 0.455612C0.163888 0.747335 0 1.143 0 1.55556V12.4444C0 13.3 0.7 14 1.55556 14H12.4444C13.3 14 14 13.3 14 12.4444V3.75667C14 3.34444 13.8367 2.94778 13.5411 2.66L11.3478 0.458889ZM7 12.4444C5.70889 12.4444 4.66667 11.4022 4.66667 10.1111C4.66667 8.82 5.70889 7.77778 7 7.77778C8.29111 7.77778 9.33333 8.82 9.33333 10.1111C9.33333 11.4022 8.29111 12.4444 7 12.4444ZM7.77778 4.66667H3.11111C2.25556 4.66667 1.55556 3.96667 1.55556 3.11111C1.55556 2.25556 2.25556 1.55556 3.11111 1.55556H7.77778C8.63333 1.55556 9.33333 2.25556 9.33333 3.11111C9.33333 3.96667 8.63333 4.66667 7.77778 4.66667Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: "icon-save",
};
</script>

<style lang="scss" scoped></style>
