<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.33 5.67L6.58996 17.41C6.14996 17.85 5.40996 17.79 5.04996 17.27C3.80996 15.46 3.07996 13.32 3.07996 11.12V6.73C3.07996 5.91 3.69996 4.98 4.45996 4.67L10.03 2.39C11.29 1.87 12.69 1.87 13.95 2.39L18 4.04C18.66 4.31 18.83 5.17 18.33 5.67Z"
      fill="currentColor"
    />
    <path
      d="M19.27 7.03963C19.92 6.48963 20.91 6.95963 20.91 7.80963V11.1196C20.91 16.0096 17.36 20.5896 12.51 21.9296C12.18 22.0196 11.82 22.0196 11.48 21.9296C10.06 21.5296 8.74001 20.8596 7.61001 19.9796C7.13001 19.6096 7.08001 18.9096 7.50001 18.4796C9.68001 16.2496 16.06 9.74963 19.27 7.03963Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: 'icon-security',
};
</script>

<style
  lang="scss"
  scoped
></style>
