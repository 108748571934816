export default {
  getListProjects(state) {
    return state.projects;
  },
  getListProjectsLength(state) {
    return state.projects.length;
  },
  getAllProjects(state) {
    return state.allProjects;
  },
};
