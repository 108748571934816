<template>
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.95504 15.237C4.93404 15.237 4.90604 15.251 4.88504 15.251C3.52704 14.579 2.42104 13.466 1.74203 12.108C1.74203 12.087 1.75603 12.059 1.75603 12.038C2.61004 12.29 3.49204 12.479 4.36704 12.626C4.52104 13.508 4.70304 14.383 4.95504 15.237Z"
      fill="currentColor"
    />
    <path
      d="M14.2581 12.115C13.5651 13.508 12.4101 14.635 11.0031 15.314C11.2691 14.425 11.4931 13.529 11.6401 12.626C12.5221 12.479 13.3901 12.29 14.2441 12.038C14.2371 12.066 14.2581 12.094 14.2581 12.115Z"
      fill="currentColor"
    />
    <path
      d="M14.3141 5.99692C13.4321 5.73092 12.5431 5.51392 11.6401 5.35992C11.4931 4.45692 11.2761 3.56091 11.0031 2.68591C12.4521 3.37891 13.6211 4.54792 14.3141 5.99692Z"
      fill="currentColor"
    />
    <path
      d="M4.95504 2.76294C4.70304 3.61694 4.52104 4.48495 4.37404 5.36695C3.47104 5.50695 2.57504 5.73095 1.68604 5.99695C2.36504 4.58995 3.49204 3.43494 4.88504 2.74194C4.90604 2.74194 4.93404 2.76294 4.95504 2.76294Z"
      fill="currentColor"
    />
    <path
      d="M10.443 5.213C8.81901 5.031 7.18101 5.031 5.55701 5.213C5.73201 4.254 5.95601 3.295 6.27101 2.371C6.28501 2.315 6.27801 2.273 6.28501 2.217C6.83801 2.084 7.40501 2 8.00001 2C8.58801 2 9.16201 2.084 9.70801 2.217C9.71501 2.273 9.71501 2.315 9.72901 2.371C10.044 3.302 10.268 4.254 10.443 5.213Z"
      fill="currentColor"
    />
    <path
      d="M4.21301 11.443C3.247 11.268 2.295 11.044 1.371 10.729C1.315 10.715 1.273 10.722 1.217 10.715C1.084 10.162 1 9.59501 1 9.00001C1 8.41201 1.084 7.83801 1.217 7.29201C1.273 7.28501 1.315 7.28501 1.371 7.27101C2.302 6.96301 3.247 6.73201 4.21301 6.55701C4.038 8.18101 4.038 9.81901 4.21301 11.443Z"
      fill="currentColor"
    />
    <path
      d="M15.0001 9.00001C15.0001 9.59501 14.9161 10.162 14.7831 10.715C14.7271 10.722 14.6851 10.715 14.6291 10.729C13.6981 11.037 12.746 11.268 11.787 11.443C11.969 9.81901 11.969 8.18101 11.787 6.55701C12.746 6.73201 13.7051 6.95601 14.6291 7.27101C14.6851 7.28501 14.7271 7.29201 14.7831 7.29201C14.9161 7.84501 15.0001 8.41201 15.0001 9.00001Z"
      fill="currentColor"
    />
    <path
      d="M10.443 12.787C10.268 13.753 10.044 14.705 9.72901 15.629C9.71501 15.685 9.71501 15.727 9.70801 15.783C9.16201 15.916 8.58801 16 8.00001 16C7.40501 16 6.83801 15.916 6.28501 15.783C6.27801 15.727 6.28501 15.685 6.27101 15.629C5.96301 14.698 5.73201 13.753 5.55701 12.787C6.36901 12.878 7.18101 12.941 8.00001 12.941C8.81901 12.941 9.63801 12.878 10.443 12.787Z"
      fill="currentColor"
    />
    <path
      d="M10.6343 11.6344C8.88355 11.8553 7.11642 11.8553 5.36565 11.6344C5.14476 9.88364 5.14476 8.11651 5.36565 6.36574C7.11642 6.14485 8.88355 6.14485 10.6343 6.36574C10.8552 8.11651 10.8552 9.88364 10.6343 11.6344Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: 'icon-global',
};
</script>

<style
  lang="scss"
  scoped
></style>
