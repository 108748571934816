<template>
  <reset-password-form />
</template>

<script>
import ResetPasswordForm from '../components/forms/reset-password-form.vue';
export default {
  components: { ResetPasswordForm },
};
</script>

<style
  lang="scss"
  scoped
></style>
