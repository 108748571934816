<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
<rect width="40" height="40" fill="#3396FF"/>
<path d="M20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40Z" fill="#3396FF"/>
<path d="M12.2327 14.8836C16.5225 10.7003 23.4779 10.7003 27.7677 14.8836L28.284 15.3871C28.4985 15.5962 28.4985 15.9353 28.284 16.1445L26.5179 17.8668C26.4106 17.9713 26.2368 17.9713 26.1295 17.8668L25.419 17.1739C22.4263 14.2555 17.5741 14.2555 14.5813 17.1739L13.8204 17.9159C13.7132 18.0205 13.5393 18.0205 13.4321 17.9159L11.666 16.1936C11.4514 15.9845 11.4514 15.6453 11.666 15.4362L12.2327 14.8836ZM31.4203 18.4454L32.9922 19.9782C33.2067 20.1874 33.2067 20.5265 32.9922 20.7356L25.9045 27.6473C25.69 27.8565 25.3422 27.8565 25.1277 27.6473L20.0973 22.742C20.0437 22.6896 19.9568 22.6896 19.9031 22.742L14.8728 27.6473C14.6583 27.8565 14.3106 27.8565 14.096 27.6473L7.00816 20.7355C6.79367 20.5264 6.79367 20.1873 7.00816 19.9782L8.58004 18.4453C8.79454 18.2362 9.1423 18.2362 9.35679 18.4453L14.3873 23.3508C14.4409 23.4031 14.5278 23.4031 14.5814 23.3508L19.6117 18.4453C19.8262 18.2361 20.1739 18.2361 20.3885 18.4453L25.4189 23.3508C25.4726 23.4031 25.5595 23.4031 25.6131 23.3508L30.6436 18.4454C30.858 18.2362 31.2058 18.2362 31.4203 18.4454Z" fill="white"/>
</svg>
      </template>
      
      <script>
      export default {
        name: "icon-link",
      };
      </script>
      
      <style lang="scss" scoped></style>
      