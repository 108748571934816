<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.4889 6.9525L13.7289 3.4325C12.7789 1.5225 11.2189 1.5225 10.2589 3.4325L8.49893 6.9525C8.25893 7.4425 7.61893 7.9125 7.08893 8.0025L3.89893 8.5325C1.85893 8.8725 1.38893 10.3525 2.84893 11.8125L5.32893 14.2925C5.74893 14.7125 5.97893 15.5225 5.84893 16.1025L5.13893 19.1725C4.57893 21.5925 5.87893 22.5425 8.01893 21.2725L11.0089 19.5025C11.5589 19.1825 12.4489 19.1825 12.9889 19.5025L15.9789 21.2725C18.1289 22.5425 19.4189 21.6025 18.8589 19.1725L18.1489 16.1025C18.0189 15.5225 18.2489 14.7125 18.6689 14.2925L21.1489 11.8125C22.6189 10.3525 22.1389 8.8725 20.0989 8.5325L16.9089 8.0025C16.3689 7.9125 15.7289 7.4425 15.4889 6.9525Z"
      fill="white"
      stroke="#7E8993"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: 'icon-star',
};
</script>

<style
  lang="scss"
  scoped
></style>
