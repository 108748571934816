<template>
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2.08325 6.66671C2.08325 4.1354 4.13528 2.08337 6.66659 2.08337C9.19789 2.08337 11.2499 4.1354 11.2499 6.66671C11.2499 9.19801 9.19789 11.25 6.66659 11.25C4.13528 11.25 2.08325 9.19801 2.08325 6.66671ZM6.66659 0.583374C3.30685 0.583374 0.583252 3.30698 0.583252 6.66671C0.583252 10.0264 3.30685 12.75 6.66659 12.75C8.07619 12.75 9.37382 12.2706 10.4053 11.4659L14.1364 15.197C14.4293 15.4899 14.9042 15.4899 15.1971 15.197C15.49 14.9041 15.49 14.4293 15.1971 14.1364L11.4659 10.4052C12.2705 9.37378 12.7499 8.07623 12.7499 6.66671C12.7499 3.30698 10.0263 0.583374 6.66659 0.583374Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: "icon-color",
};
</script>

<style lang="scss" scoped></style>
