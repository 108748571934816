<template>
  <svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.0001 29.8333C23.3639 29.8333 29.3334 23.8638 29.3334 16.5C29.3334 9.13621 23.3639 3.16667 16.0001 3.16667C8.63629 3.16667 2.66675 9.13621 2.66675 16.5C2.66675 23.8638 8.63629 29.8333 16.0001 29.8333Z"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M20.6667 16.5H12.6667"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M15.3333 12.5L11.3333 16.5L15.3333 20.5"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: "icon-back",
};
</script>

<style lang="scss" scoped></style>
