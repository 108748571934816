<template>
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="8" y="5.59998" width="25.6" height="3.2" rx="1.6" transform="rotate(45 8 5.59998)" fill="currentColor" />
    <rect
      x="5.59998"
      y="24"
      width="25.6"
      height="3.2"
      rx="1.6"
      transform="rotate(-45 5.59998 24)"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: "icon-close",
};
</script>

<style lang="scss" scoped></style>
