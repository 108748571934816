// import * as _ from 'lodash';
import { Decimal } from 'decimal.js';
import '@/assets/scss/index.scss';
import './rules/index.js';

import Vue, { createApp } from 'vue';
import Vuex from 'vuex';
import uuid from 'vue-uuid';

import App from './App.vue';
import store from './store/index';
import router from './router/index';
import Notifications from '@kyvg/vue3-notification'
import apiClient from './utils/helpers/api-client.js';
import configs from '#/configs/configs';
import lodash from 'lodash';

import { VueQueryPlugin, QueryClient } from '@tanstack/vue-query';
import { WagmiPlugin } from '@wagmi/vue';
import { config } from './components/modals/config.js';

// window._ = _;
// Object.defineProperty(Vue.prototype, '$decimal', { value: Decimal });
// Object.defineProperty(Vue.prototype, '$_', { value: lodash });

Vue.use(Vuex);
Vue.use(uuid);

// Vue.prototype.$api = ApiClient;
Vue.prototype.$configs = configs;


const app = createApp(App)
const queryClient = new QueryClient();

app.config.globalProperties.$api = apiClient;


app.use(Notifications)
app.use(WagmiPlugin, { config })
app.use(VueQueryPlugin, { queryClient })
app.use(store);
app.use(router)
app.mount('#app');


/** old vue syntax */
// const main = new Vue({
//   store,
//   router,
//   el: '#app',
//   render: (h) => h(App),
// });
