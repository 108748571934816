<template>
  <div class="cm-loader">
    <span class="cm-loader__loader"></span>
  </div>
</template>

<script>
export default {
  name: 'cm-loader',
};
</script>

<style
  lang="scss"
  scoped
></style>
