<template>
  <svg
    width="14"
    height="15"
    viewBox="0 0 14 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.79338 0.833334H4.20671C1.78004 0.833334 0.333374 2.28 0.333374 4.70667V10.2867C0.333374 12.72 1.78004 14.1667 4.20671 14.1667H9.78671C12.2134 14.1667 13.66 12.72 13.66 10.2933V4.70667C13.6667 2.28 12.22 0.833334 9.79338 0.833334ZM10.2534 9.05333C10.2534 9.11333 10.24 9.17333 10.22 9.23333C10.1734 9.34667 10.08 9.44 9.96671 9.48667C9.90671 9.51333 9.84671 9.52 9.78671 9.52H8.54671C8.28671 9.52 8.08004 9.31333 8.08004 9.05333C8.08004 8.79333 8.28671 8.58667 8.54671 8.58667H8.66671L7.26004 7.18L6.58004 8.19333C6.50004 8.30667 6.38004 8.38667 6.24004 8.4C6.10004 8.41333 5.96671 8.36667 5.86671 8.26667L3.88004 6.28C3.70004 6.1 3.70004 5.80667 3.88004 5.62C4.06004 5.43333 4.35337 5.44 4.54004 5.62L6.12671 7.20667L6.80671 6.19333C6.88671 6.08 7.00671 6 7.14671 5.98667C7.29337 5.97333 7.42004 6.02 7.52004 6.12L9.33337 7.93333V7.81333C9.33337 7.55333 9.54004 7.34667 9.80004 7.34667C10.06 7.34667 10.2667 7.55333 10.2667 7.81333V9.05333H10.2534Z"
      fill="#FC4848"
    />
  </svg>
</template>

<script>
export default {
  name: 'icon-percent-down',
};
</script>

<style
  lang="scss"
  scoped
></style>
