<template>
  <login-form />
</template>

<script>
import loginForm from '../components/forms/login-form.vue';

export default {
  components: { loginForm },
};
</script>

<style
  lang="scss"
  scoped
></style>
