<template>
  <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M22 21.25H21V15C21 10.03 16.97 6 12 6C7.03 6 3 10.03 3 15V21.25H2C1.59 21.25 1.25 21.59 1.25 22C1.25 22.41 1.59 22.75 2 22.75H22C22.41 22.75 22.75 22.41 22.75 22C22.75 21.59 22.41 21.25 22 21.25Z"
      fill="currentColor"
    />
    <path
      d="M12 3.75C11.59 3.75 11.25 3.41 11.25 3V2C11.25 1.59 11.59 1.25 12 1.25C12.41 1.25 12.75 1.59 12.75 2V3C12.75 3.41 12.41 3.75 12 3.75Z"
      fill="currentColor"
    />
    <path
      d="M4.99994 5.75C4.80994 5.75 4.61994 5.68 4.46994 5.53L3.46994 4.53C3.17994 4.24 3.17994 3.76 3.46994 3.47C3.75994 3.18 4.23994 3.18 4.52994 3.47L5.52994 4.47C5.81994 4.76 5.81994 5.24 5.52994 5.53C5.37994 5.68 5.18994 5.75 4.99994 5.75Z"
      fill="currentColor"
    />
    <path
      d="M18.9999 5.75C18.8099 5.75 18.6199 5.68 18.4699 5.53C18.1799 5.24 18.1799 4.76 18.4699 4.47L19.4699 3.47C19.7599 3.18 20.2399 3.18 20.5299 3.47C20.8199 3.76 20.8199 4.24 20.5299 4.53L19.5299 5.53C19.3799 5.68 19.1899 5.75 18.9999 5.75Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: "icon-alarm",
};
</script>

<style lang="scss" scoped></style>
