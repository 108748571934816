<template>
  <svg width="10" height="6" viewBox="0 0 10 6" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0.212553 1.22664L2.35255 3.36664L3.65922 4.67997C4.21255 5.2333 5.11255 5.2333 5.66589 4.67997L9.11922 1.22664C9.57255 0.773305 9.24589 -2.86102e-05 8.61255 -2.86102e-05L4.87255 -2.86102e-05H0.719219C0.0792198 -2.86102e-05 -0.240781 0.773305 0.212553 1.22664Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: "icon-toggle-bottom",
};
</script>

<style lang="scss" scoped></style>
