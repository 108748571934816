<template>
  <forgot-password-form />
</template>

<script>
import ForgotPasswordForm from '../components/forms/forgot-password-form.vue';
export default {
  components: { ForgotPasswordForm },
};
</script>

<style
  lang="scss"
  scoped
></style>
