<template>
  <register-form />
</template>

<script>
import RegisterForm from '../components/forms/register-form.vue';
export default {
  components: { RegisterForm },
};
</script>

<style
  lang="scss"
  scoped
></style>
